import React, { useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Link from "../link";

import { trackEvent } from "../googleAnalytics";
import { Trans, useTranslation } from "react-i18next";
import { useCountryRestriction } from "../countryRestrictor";
import { supabase } from "../../../utils/supabase";

const validateEmail = (email) => {
  /* eslint-disable-next-line */
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const NewsletterForm = () => {
  const { country } = useCountryRestriction();
  const [t] = useTranslation();
  const generalUpdates = useRef(false);
  const [email, setEmail] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [errorMessage, setError] = useState(null);

  const [emailError, setEmailError] = useState(false);
  const [generalUpdatesError, setGeneralUpdatesError] = useState(false);

  const onChange = (e) => {
    setEmail(e.currentTarget.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();

    setError(null);

    const validations = {
      generalUpdates: generalUpdates.current.checked === true,
      email: validateEmail(email),
    };

    setGeneralUpdatesError(!validations.generalUpdates);
    setEmailError(!validations.email);

    if (validations.email && validations.generalUpdates) {
      const data = {
        country_release: country,
        email,
        general_updates: generalUpdates.current.checked,
        source: "legacy.amiinporn.org",
      };

      trackEvent({
        category: "NewsletterForm",
        action: "Submit",
      });

      submit(data);
    }
  };

  const submit = async (data) => {
    setLoading(true);

    try {
      const { error } = await supabase
        .from("newsletter_recipients")
        .insert([data]);

      if (!error) {
        setSuccess(true);
      } else {
        throw new Error();
      }
    } catch {
      setError(t("newsletterForm.error"));
    } finally {
      setLoading(false);
    }
  };

  const form = {
    body: (
      <>
        <Row className="justify-content-center">
          <Col lg="8">
            <Form.Group controlId="email">
              <Form.Label>
                <strong>{t("newsletterForm.notifyMe")}</strong>
              </Form.Label>
              <Form.Control
                isInvalid={emailError}
                type="email"
                onChange={onChange}
                placeholder="Enter email"
              />
              <Form.Text className="text-muted">
                {t("newsletterForm.weHateSpam")}
              </Form.Text>
            </Form.Group>
            <br />
            <Form.Group controlId="general-updates">
              <Form.Check
                ref={generalUpdates}
                isInvalid={generalUpdatesError}
                type="checkbox"
                label={t("newsletterForm.confirm")}
              />
            </Form.Group>
            <Form.Text>
              <Trans i18nKey="newsletterForm.notePrivacy">
                You can find further information in our{" "}
                <Link to="privacy">privacy policy</Link>
              </Trans>
            </Form.Text>
            {errorMessage && (
              <>
                <br />
                <p className="h6 text-danger">{errorMessage}</p>
              </>
            )}
          </Col>
        </Row>
      </>
    ),
    footer: (
      <>
        <Button disabled={isLoading} key="1" type="submit" onClick={onSubmit}>
          {isLoading
            ? t("newsletterForm.btnLoading")
            : t("newsletterForm.btnSubmit")}
        </Button>
      </>
    ),
  };

  const success = {
    body: (
      <>
        <p className="h4 mt-3 text-center">
          {t("newsletterForm.successHeadline")}
          <br />
        </p>
        <p className="h5 mb-3 text-center">
          {t("newsletterForm.successSubline")}
        </p>
      </>
    ),
    footer: null,
  };

  const body = isSuccess ? success.body : form.body;
  const footer = isSuccess ? success.footer : form.footer;

  return (
    <Form>
      {body}
      {footer}
    </Form>
  );
};

export default NewsletterForm;
